import Menu from "./App/Menu";
import { Col } from "react-bootstrap";
// import Footer from "./Interface/Footer";
// import Header from "./Interface/Header";
// import Pages from "./Pages/Pages";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";

import { BrowserRouter } from "react-router-dom";
import MySpinner from "./Elements/MySpinner";
import Notifications from "./App/Notifications";
import { postJSON, removeEffects } from "./Global/functions";
import { URL_API_APP } from "./Global/constants";
import Loading from "./App/Loading";
import Informations from "./App/Informations";
// import Login from "./App/Login";
// import { removeEffects } from "./Global/functions";
// import { __DEV__ } from "./Global/constants";

const Footer = lazy(() => import("./App/Footer"))
const Header = lazy(() => import("./App/Header"))
const Pages = lazy(() => import("./Pages/Pages"))

const Login = lazy(() => import("./App/Login"))

const getLocalToken = () => {
  const old = localStorage.getItem("token");
  if (!old) return null;
  try {
    return JSON.parse(old);
  }
  catch (e) {
    return null;
  }
}

let abort = null;

function App() {
  const [ token, setToken ] = useState(null);
  const [ menuSmall, setMenuSmall ] = useState(!!localStorage.getItem("menu"));
  const [ info, setInfo ] = useState(false);
  const [ inited, setInited ] = useState(false);

  const handleMenuSmall = small => {
    setMenuSmall(small);
    if (small) localStorage.setItem("menu", "1");
    else localStorage.removeItem("menu")
  }

  useEffect(() => {
    removeEffects(document.getElementById("root"), 10, null, true);
    document._logout = () => {
      localStorage.removeItem("token");
      setToken(null);
      window.location.href = "/";
    }

    if (!inited && !abort) {
      const tkn = getLocalToken();
      if (tkn && tkn?.token) {
        if (abort) abort.abort()
        postJSON({
                   url: `${ URL_API_APP }/logon/token`,
                   post: { token: tkn.token },
                 }, abort = new AbortController())
          .then(() => {
            setToken(tkn);
          })
          .catch(() => {
            localStorage.removeItem("token")
          })
          .finally(() => {
            setInited(true);
            abort = null;
          })
      }
      else setInited(true);
    }

    return () => {
      delete document._logout;
    }
  }, [ inited ])

  const handleLogin = useCallback((token) => {
    setToken(token);
    localStorage.setItem("token", JSON.stringify(token));
  }, [])

  if (!inited) return <>
    <Notifications/>
    <Loading
      children="Инициализация приложения"
      className="my-2 mx-auto"
    />
  </>

  if (!token) return <>
    <Notifications/>
    <Login
      onLogin={ handleLogin }
    />
  </>

  return (<>
      <Notifications/>
      <BrowserRouter>
        <Informations
          visible={ info }
          onHide={ () => setInfo(false) }
        />

        <div
          className="d-flex flex-column gap-0 g-0 h-100"
          style={ {
            minHeight: "100vh",
            // overflowX: "hidden",
          } }
        >
          <div
            // xs="auto"
            // as={ Col }
            className="d-flex flex-nowrap flex-grow-1 gap-0 g-0 align-items-stretch"
          >
            <div
              // xs="auto"
              className="flex-shrink-0"
              id="menu"
              children={
                <Suspense
                  fallback={ <MySpinner children="Загрузка меню"/> }
                  children={
                    <Menu
                      // token={ token }
                      small={ menuSmall }
                      setSmall={ handleMenuSmall }

                      info={ info }
                      onInfo={ setInfo }
                    />
                  }
                />
              }
            />
            <div
              // as={ Col }
              className="d-flex flex-column gap-0 g-0 overflow-auto flex-grow-1"
            >
              <Col
                id="header"
                xs="auto"
                children={
                  <Suspense
                    fallback={ <MySpinner children="Загрузка шапки" className="my-1"/> }
                    children={ <Header/> }
                  />
                }
              />
              <Col
                id="content"
                className="h-100"
                style={ {
                  minHeight: "300px",
                  // width: `calc(100% - ${ Menu.getWidth(menuSmall) }px)`,
                } }
                children={
                  <Suspense
                    fallback={ <MySpinner children="Загрузка страницы" className="my-4"/> }
                    children={ <Pages/> }
                  />
                }
              />
            </div>
          </div>

          <Col
            xs="auto"
            as="footer"
            id="footer"
            children={
              <Suspense
                fallback={ <MySpinner children="Загрузка footer" className="my-3"/> }
                children={
                  <Footer
                    // token={ token }
                    // setToken={ setToken }
                    small={ menuSmall }
                    // setSmall={ handleMenuSmall }
                  />
                }
              />
            }
          />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
