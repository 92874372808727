import { Col, Row, Spinner } from "react-bootstrap";

const MySpinner = ({ className = " mx-auto", children, ...props }) =>
  <Row
    className={ `g-2 align-items-center justify-content-center ${ className }` }
  >
    <Col
      xs="auto"
      children={
        <Spinner
          size="sm"
          animation="border"
          { ...props }
          // style={ {
          //   ...style,
          //   "--bs-spinner-vertical-align": "-0.5rem",
          // } }
        />
      }
    />
    <Col
      xs="auto"
      className="small text-muted"
      children={ children }
    />
  </Row>

MySpinner.propTypes = {
  ...Spinner.propTypes,
}

MySpinner.defaultProps = {
  ...Spinner.defaultProps,
}

export default MySpinner;