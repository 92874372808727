import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { BeelineIcon } from "./BeelineIcon";

import { ReactComponent as IconUpdate } from "@beeline/design-tokens/assets/icons/refresh.svg";

const ButtonIcon = ({ icon, text, className = "", inline = true, loading = false, ...props }) => {
  const widths = { xsm: 14, sm: 18, md: 20, lg: 22 }
  const w = widths[props?.size] || widths.sm;

  return <Button
    { ...props }
    className={ `${ className } d${ inline ? "-inline" : "" }-flex gap-2 align-items-center` }
    disabled={ props?.disabled || loading }
  >
    {
      icon &&
      <BeelineIcon
        icon={ loading ? IconUpdate : icon }
        size={ w }
        className={ [
          (!text || !text.length) && "m-0",
          loading && "rotate360",
        ].filter(a => !!a).join(" ") }
      />
    }
    { text && text.length > 0 && <div children={ text }/> }
  </Button>
}

ButtonIcon.propTypes = {
  ...Button.propTypes,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([ "sm", "lg", "md", "xs", "xsm" ]),
  icon: PropTypes.oneOfType([ PropTypes.elementType, PropTypes.element ]),
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

ButtonIcon.defaultProps = {
  ...Button.defaultProps,
  inline: false,
  disabled: false,
  loading: false,
}

export default ButtonIcon;