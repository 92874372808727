import React from "react";
import PropTypes from "prop-types";

export const BeelineIcon =
  ({ width, height, size, fill, icon, className, ...props }) => {
    const prps = {
      // ref: ref,
      ...props,
      width: (width !== undefined ? width : size) || 18,
      height: (height !== undefined ? height : size) || 18,
      fill: fill || "currentColor",
      className: className,
    };

    return React.isValidElement(icon)
           ? React.cloneElement(icon, {
        ...prps,
        ...icon?.props,
      })
           : React.createElement(icon || "div", prps)
  }

BeelineIcon.propTypes = {
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  size: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  fill: PropTypes.string,
  icon: PropTypes.oneOfType([ PropTypes.elementType, PropTypes.element ]),
  className: PropTypes.string,
}
BeelineIcon.defaultProps = {
  // width: 16,
  // height: 16,
  // size: 16,
  fill: "currentColor",
}