import React from "react";
import { _DEFAULT_TIMEOUT, months, of_months, URL_API_APP, weekdays } from "./constants";
import ServerErrors from "./Strings/errorsServer.json";
import CoreErrors from "./Strings/errorsCore.json";

export const getWeekday = date => date && date.getDay ? (date.getDay() + 6) % 7 : null;

export const resetMonth = date => date && date instanceof Date ? new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0) : null;
export const resetTime = date =>
  date ? (
    (date = parseDate(date)) &&
    new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
  ) : null;

export const compareDates = (a, b) => resetTime(a) - resetTime(b);

export const compareMonths = (a, b) => resetMonth(a) - resetMonth(b);

export const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

export const getFirstDayOfWeek = (year, month) => getWeekday(new Date(year, month, 1, 0, 0, 0, 0));

export function parseDate(val, hour = null, minute = null, second = null, millisecond = null) {
  if (val === undefined || !val) return null;

  if (typeof hour === "string" && /^\d+:\d+/.test(hour)) {
    [ hour, minute, second, millisecond ] = [ ...hour.split(":"), 0, 0, 0, 0 ].slice(0, 4).map(a => parseInt(a) || 0);
  }
  const updateTime = date => {
    if (!(val instanceof Date)) return date;
    const d = new Date(date);
    if (hour !== null) d.setHours(hour, minute, second, millisecond);
    else if (minute !== null) d.setMinutes(minute, second, millisecond);
    else if (second !== null) d.setSeconds(second, millisecond);
    else if (millisecond !== null) d.setMilliseconds(millisecond);
    return d;
  }
  if (val instanceof Date) return updateTime(new Date(val.getTime()));

  if (typeof val === "string") {
    if (/^\d{4}-\d{2}-\d{2}$/.test(val)) {
      let [ , y, m, d ] = val.match(/(\d{4})-(\d{2})-(\d{2})/);
      if (m > 12) m = 12;
      m--;
      if (d > daysInMonth(y, m)) d = daysInMonth(y, m);

      return new Date(parseInt(y), parseInt(m), parseInt(d), hour, minute, second, millisecond);
    }
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}[+-]\d{2}:\d{2}$/.test(val)) return new Date(val)
    if (/^\d{2}\.\d{2}\.\d{4}$/.test(val)) {
      let [ , d, m, y ] = val.match(/(\d{2})\.(\d{2})\.(\d{4})/);
      if (m > 12) m = 12;
      m--;
      if (d > daysInMonth(y, m)) d = daysInMonth(y, m);

      return new Date(parseInt(y), parseInt(m), parseInt(d), hour, minute, second, millisecond);
    }
    if (/^\d+$/.test(val)) return new Date(parseFloat(val) * 1000);
  }
  else if (typeof val === "number") {
    if (Math.floor(val).toString(10).length < 11) val *= 1000;
    return updateTime(new Date(val));
  }
  else if (Array.isArray(val)) return new Date(val[0], val[1] - 1, val[2], hour, minute, second, millisecond)
  return null;
}

export function isArray(param) { return param ? Object.prototype.toString.call(param) === '[object Array]' || param.constructor === Array || Object.prototype.toString.call(param) === '[object NodeList]' || Object.prototype.toString.call(param) === '[object HTMLCollection]' : false; }

export function cancelEvent(e) {
  if (!e) return false;
  if (e.stopPropagation) e.stopPropagation();
  if (e.preventDefault) e.preventDefault();
  e.cancelBubble = true;
  e.cancel = true;
  e.returnValue = false;
  return false;
}

/*
const ServerError = (id, params) => {
  const lng = ServerErrorsRu;
  let res = lng[id]
  if (params && typeof params === "object") {
    Object.keys(params)
      .forEach(key => {
        if (key === "core_err") res = res.replaceAll("%CORE_ERR_MESSAGE%", CoreErrors[params[key].toString()] || _t2("Core error {0}", params[key]))
        else res = res.replaceAll(`%${ key.toUpperCase() }%`, params[key] || key.toUpperCase())
      })
  }
  return res || _t2("Error {0}", id);
}

function PostError(err) {
  if (err?.error_code !== undefined) return { ...err, description: ServerError(err.error_code, err?.error_params || null) || err?.description || _t("Unknown error") }
  if (err?.status && httpErrors[err?.status] !== undefined) {
    let description = err?.description || null;
    if (!description || !description.length) description = `${ _t2("Error {0}", err.status) } – ${ httpErrors[err.status] }`;
    return {
      ...err,
      description: description,
    }
  }
  return err;
}


const fetchError = err => {
  if (err && err.name === "AbortError") return;
  if (err.status === 401) {
    if (document._logout) document._logout();
  }
  throw new PostError(err);
};
// */

export const getArray = arr => arr && Array.isArray(arr) ? arr : [];
export const getObject = obj => obj && typeof obj === "object" ? { ...obj } : {};

const get = key => {
  const r = localStorage.getItem(key);
  return r ? JSON.parse(r) : {};
}

export const getClientID = () => {
  const { user } = get("token");
  const backoffice = process.env.REACT_APP_BACKOFFICE === "true" && user?.backoffice === true;
  const id = !backoffice ? user?.projects && user?.projects[0] : getArray(window.location.pathname.match(/\/?backoffice\/(\d+)/))[1];

  return id !== "" && id !== undefined && id !== 0 && id !== "0" ? parseInt(id) : null;
}

/*
export async function postJSON(options, controller = null, force = false) {
  const { url, timeout = _DEFAULT_TIMEOUT, method = "POST" } = options;
  // const { token, user } = get("token");
  const token = getTokenKey();
  const user_id = getTokenUserID();
  const clientId = getClientID();

  const post = {
    token: token,
    clientId: clientId ? parseInt(clientId) : null,
    userId: user_id,
    ...(options?.post || options?.params),
  };

  controller = controller || new AbortController()
  const timer = setTimeout(() => controller.abort(), timeout);

  return fetch(url, {
    // mode: 'no-cors',
    mode: 'cors',
    method: method || 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(post),
    signal: controller.signal,
  })
    .then(data => {
      if (data.status !== 200 && data.status !== 201) return Promise.reject(data);
      // try {
      //   if (data && data.json) return data.json();
      // }
      // catch (err) {
      return data.text();
      // }
    })
    .then(t => {
      try {
        if (typeof t === "string") t = JSON.parse(t);
      }
      catch (e) {}
      return t;
    })
    .catch(err => {
      if (force) throw err;
      else if (err?.name === "AbortError") return "";
      else if ("SyntaxError" === err?.name) return fetchError({ ...err, description: err.message });
      else if (err?.name && err?.message) return fetchError({ ...err, description: err.message });
      else return fetchError(err)
    })
    .finally(() => {
      if (timer) clearTimeout(timer);
    })
}

export function downloadFile(url, filename, post, timeout = 60000) {
  // const { timeout = _DEFAULT_TIMEOUT, method = "POST" } = data || {};
  const { token, user } = get("token");
  const backoffice = !!user?.backoffice;
  const project = !backoffice ? user?.projects && user?.projects[0] : getArray(window.location.pathname.match(/\/?backoffice\/(\d+)/))[1] || null;

  const controller = new AbortController()
  setTimeout(() => controller.abort(), timeout);

  return fetch(url, {
    // mode: 'no-cors',
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
                           ...post,
                           token: token,
                           clientId: project ? parseInt(project) : null,
                           userId: user?.id,
                         }),
    signal: controller.signal,
  })
    .then(async data => {
      if (data.status !== 200 && data.status !== 201) return Promise.reject(data);
      return await data.blob();
    })
    .then(blob => {
      // const b = new Blob([ blob ], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    })
    .catch(fetchError)
}

export function saveFile(filename, data) {
  const link = document.createElement('a');
  link.download = filename;
  link.href = data
  document.querySelector("body").appendChild(link)
  link.click();
  document.querySelector("body").removeChild(link)
}

//*/
export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function cursorPos(e) {
  let posx = 0;
  let posy = 0;
  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  }
  else if (e.clientX || e.clientY) {
    posx = e.clientX + document.body.scrollLeft
           + document.documentElement.scrollLeft;
    posy = e.clientY + document.body.scrollTop
           + document.documentElement.scrollTop;
  }
  // posx and posy contain the mouse position relative to the document
  // Do something with this information
  return {
    x: posx,
    y: posy,
  };
}

export function findPos(obj, parent) {
  if (typeof obj == "string") obj = document.getElementById(obj);
  if (!obj) return [ 0.0, 0.0 ];
  let { left: curleft, top: curtop } = obj.getBoundingClientRect();
  // let curleft = r.left,
  //   curtop = r.offsetTop,
  const body = document.querySelector('body');
  obj = obj.offsetParent;
  do {
    if (!obj || obj === parent || obj === body) break;
    // if (parent === true && [ 'fixed', 'relative', 'absolute' ].indexOf(deepCss(obj, 'position')) !== -1) break;
    // if (parent === true && [ 'fixed', 'absolute' ].indexOf(deepCss(obj, 'position')) !== -1) break;
    if (parent !== true && [ 'fixed' ].indexOf(deepCss(obj, 'position')) !== -1) break;
    const r = obj.getBoundingClientRect();

    curleft += r.left;
    curtop += r.top;
    // curleft += obj.offsetLeft;
    // curtop += obj.offsetTop;
  }
  while (!!(obj = obj.offsetParent));

  return [ curleft, curtop ];
}

export function getScrollXY() {
  let scrOfX = 0, scrOfY = 0;
  if (typeof (window.pageYOffset) == 'number') {
    //Netscape compliant
    scrOfY = window.pageYOffset;
    scrOfX = window.pageXOffset;
  }
  else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
    //DOM compliant
    scrOfY = document.body.scrollTop;
    scrOfX = document.body.scrollLeft;
  }
  else if (document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
    //IE6 standards compliant mode
    scrOfY = document.documentElement.scrollTop;
    scrOfX = document.documentElement.scrollLeft;
  }
  return [ scrOfX, scrOfY ];
}

export function getInPx(el, val, param) {
  const res = parseFloat(val);

  if (val.match(/px$/i)) return res;
  if (val.match(/rem$/i)) return res * parseFloat(deepCss(document.querySelector('html'), 'font-size', true));
  if (val.match(/%$/i)) return res / 100 * (param && param.match(/(height|top|bottom)/i) ? el.offsetHeight : el.offsetWidth);//window.innerHeight : window.innerWidth);
  if (val.match(/em$/i)) return res * parseFloat(deepCss(el, 'font-size'));
  if (val.match(/vw$/i)) return res / 100 * window.innerWidth;
  if (val.match(/vh$/i)) return res / 100 * window.innerHeight;

  return val;//res;
}

export function rem2px(val) { return getInPx(null, val + 'rem'); }

export function deepCss(who, css, convertInPx) {
  if (isArray(css)) {
    let res, i;
    for (res = 0, i = css.length; i--;) res += deepCss(who, css[i], convertInPx);
    return res;
  }
  const styles = window.getComputedStyle(who);
  const val = styles.getPropertyValue(css);
  return val ? (convertInPx ? getInPx(who, val, css) : val) : null;
}

export const Padding = {
  top: el => !el ? 0 : (deepCss(el, 'padding-top', true)),
  bottom: el => !el ? 0 : (deepCss(el, 'padding-bottom', true)),
  left: el => !el ? 0 : (deepCss(el, 'padding-left', true)),
  right: el => !el ? 0 : (deepCss(el, 'padding-right', true)),
  width: el => !el ? 0 : (deepCss(el, 'padding-left', true)) + (deepCss(el, 'padding-right', true)),
  height: el => !el ? 0 : (deepCss(el, 'padding-top', true)) + (deepCss(el, 'padding-bottom', true)),
}
export const Margin = {
  top: el => !el ? 0 : (deepCss(el, 'margin-top', true)),
  bottom: el => !el ? 0 : (deepCss(el, 'margin-bottom', true)),
  left: el => !el ? 0 : (deepCss(el, 'margin-left', true)),
  right: el => !el ? 0 : (deepCss(el, 'margin-right', true)),
  width: el => !el ? 0 : (deepCss(el, 'margin-left', true)) + (deepCss(el, 'margin-right', true)),
  height: el => !el ? 0 : (deepCss(el, 'margin-top', true)) + (deepCss(el, 'margin-bottom', true)),
}
export const Border = {
  top: el => !el ? 0 : (deepCss(el, 'border-top-width', true)),
  bottom: el => !el ? 0 : (deepCss(el, 'border-bottom-width', true)),
  left: el => !el ? 0 : (deepCss(el, 'border-left-width', true)),
  right: el => !el ? 0 : (deepCss(el, 'border-right-width', true)),
  width: el => !el ? 0 : (deepCss(el, 'border-left-width', true)) + (deepCss(el, 'border-right-width', true)),
  height: el => !el ? 0 : (deepCss(el, 'border-top-width', true)) + (deepCss(el, 'border-bottom-width', true)),
}

export function setValidClasses(el, valid) {
  if (Array.isArray(el)) return el.forEach(e => setValidClasses(e => valid));
  if (!el || !el.classList) return;
  if (valid) {
    if (el.classList.contains("is-valid")) return;
    el.classList.remove("is-invalid")
    // el.classList.add("is-valid")
  }
  else if (valid === null) {
    if (!el.classList.contains("is-valid") && !el.classList.contains("is-invalid")) return;
    el.classList.remove("is-valid", "is-invalid")
  }
  else {
    if (el.classList.contains("is-invalid")) return;
    el.classList.remove("is-valid")
    el.classList.add("is-invalid")
  }
}

export function add0(val, len = 2, char = "0") { return `${ char.repeat(len) }${ parseInt(val) }`.substr(-len) }

export function removeProp(prop, ...keys) {
  let result = Object.assign({}, prop);
  if (keys.forEach && keys.length > 1) keys.forEach(k => result = removeProp(result, k));
  else delete result[keys[0]];

  return result
}

export function isEqualObjects(a, b, ...keys) {
  const isNull = val => val === undefined || val === null;// || val === "";
  if (isNull(a) && isNull(b)) return true;
  if (isNull(a) || isNull(b)) return false;

  if (typeof a !== typeof b) return false;
  if (React.isValidElement(a)) return React.isValidElement(b) && (a?.value === b?.value);// || React.isValidElement(b)) return true;
  if (typeof a === "function") return typeof b === "function";
  if (a instanceof Date) return (b instanceof Date) && a.getTime() === b.getTime();
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) return false;
    if (a.length !== b.length) return false;
    return a.every((aa, idx) => isEqualObjects(aa, b[idx]));
  }
  if (typeof a === "object" && !Array.isArray(a)) {
    if (typeof b !== "object") return false;
    // if (a.constructor.name === "FiberNode") return b.constructor.name === "FiberNode";
    if (a.constructor.name !== "Object") return a.constructor.name === b.constructor.name;
    if (keys === undefined || !Array.isArray(keys) || keys.length === 0) {
      keys = Object.keys(a)
      if (keys.length !== Object.keys(b).length) return false;
    }
    else keys = keys.flat()
    if (!keys.length) return true;

    return keys.every(key => a.hasOwnProperty(key) === b.hasOwnProperty(key)
                             && isEqualObjects(a[key], b[key]),
    );
  }
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) return false;
    if (a.length !== b.length) return false;
    for (let i = a.length; i--;) if (b[i] === undefined || !isEqualObjects(a[i], b[i])) return false;
    return true;
  }
  if (Array.isArray(b)) return false;
  if (typeof a === "number") return typeof b === "number" && a === b;

  return (a).toString() === (b).toString();
}

export function cloneObject(obj) {
  if (obj === null || obj === undefined) return obj;
  if (React.isValidElement(obj)) return obj;
  if (Array.isArray(obj)) return obj.map(v => cloneObject(v));
  if (obj instanceof Date) return new Date(obj);
  if (typeof obj === "object") {
    const res = {};
    Object.keys(obj).forEach(k => res[k] = cloneObject(obj[k]));
    return res;
  }
  return obj;
}

export function getToken() {
  const tokenString = localStorage.getItem('token');
  try {
    if (!tokenString) return null;
    const parsed = JSON.parse(tokenString);
    if (!parsed?.token) return null;
    if (process.env.REACT_APP_BACKOFFICE !== "true" && parsed?.backoffice === true) return null;
    return parsed;
  }
  catch (err) {
    return {};
  }
}

export function getTokenKey() {
  const { token } = getToken() || {};
  return token || null;
}

export function getTokenUser() {
  const { user } = getToken() || {};
  return user || null;
}

export function getTokenUserID() { return getTokenUser()?.id || null }

export const cancelFocus = e => e?.target?.blur();

export function getPageSize() {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = e.clientWidth || w.innerWidth || g.clientWidth,
    y = e.clientHeight || w.innerHeight || g.clientHeight;

  return [ x, y ];
}

export const getElement = (elem, props) => {
  if (!elem) return <></>;
  return !React.isValidElement(elem)
         ? React.createElement(elem, props)
         : React.cloneElement(elem, props)
}

const numPower = (num, pow, b, fraction = 2, format = true) => {
  if (b === null || b === undefined) return null;
  if (typeof b !== "number") b = parseFloat(b);
  b /= Math.pow(num, pow);
  if (format) return formatNumber(b, fraction);
  return b.toFixed(fraction)
}

const bytes2 = (pow, b, fraction = 2, format = true) => numPower(1024, pow, b, fraction, format)

export const bytes2kb = (b, fraction = 2, format = true) =>
  bytes2(1, b, fraction, format);

export const bytes2mb = (b, fraction = 2, format = true) =>
  bytes2(2, b, fraction, format);

export const bytes2gb = (b, fraction = 2, format = true) =>
  bytes2(3, b, fraction, format);

export const bytes2tb = (b, fraction = 2, format = true) =>
  bytes2(4, b, fraction, format);

export const bytesFormat = (format, b) => {
  if (format === "t") return bytes2tb(b, 2, true)
  else if (format === "g") return bytes2gb(b, 2, true)
  else if (format === "m") return bytes2mb(b, 2, true)
  else if (format === "k") return bytes2kb(b, 0, true)

  return formatNumber(b, 0)
}

export const numFormat = (format, b) => {
  if (format === "t") return numPower(1000, 4, b, 2, true)
  else if (format === "g") return numPower(1000, 3, b, 2, true)
  else if (format === "m") return numPower(1000, 2, b, 2, true)
  else if (format === "k") return numPower(1000, 1, b, 0, true)

  return formatNumber(b, 0)
}

export const bytesUnit = format => ({ b: "", k: "К", m: "М", g: "Г", t: "Т" }[format] || "")

export const timeAsText = (date, seconds = false) => {
  if (date && !(date instanceof Date)) date = parseDate(date);
  if (date && date instanceof Date) return `${ add0(date.getHours()) }:${ add0(date.getMinutes()) }${ seconds ? ":" + add0(date.getSeconds()) : '' }`
  return `--:--${ seconds ? ":--" : "" }`;
}

export const getPeriodLabel = (v, period, min, max) => {
  if (!v) return "-";
  if (!(v instanceof Date)) v = parseDate(v);
  if (!v) return "-";

  const fDate = (v, force = false) => [
    v.getDate(),
    of_months[v.getMonth()].toLowerCase().substr(0, 3),
    force || new Date(min).getFullYear() !== new Date(max).getFullYear() ? v.getFullYear() : null,
  ].filter(a => a).join(" ");

  const d = (max - min) / 86400 / 1000;
  if (!period || period === "dates") {
    if (d <= 1 / 24) period = "hour";
    else if (d <= 1) period = "day";
    else if (d <= 7) period = "week";
      // else if (d <= 14) period = "twoweeks";
    // else if (d <= 31) period = "month";
    else if (d <= 31) period = "week";
    else if (d <= 90) period = "month";
    else period = "year";
  }

  switch (period) {
    case 'year':
      return [
        capitalize(of_months[v.getMonth()].substring(0, 3)),
        v.getFullYear().toString().substring(2),
      ].join(" ");
    // return fDate(v, true)

    case 'month':
    case 'week':
    case 'twoweeks':
    case 'quarter':
      return fDate(v)

    case 'day':
    case 'hour':
      if (date2str(max) !== date2str(min)) return fDate(v) + " " + timeAsText(v);
      return timeAsText(v);

    case 'dates':
      if (max && (max - min) / 1000 <= 86400) {
        if (date2str(max) !== date2str(min)) return fDate(v) + " " + timeAsText(v);
        return timeAsText(v);
      }
      return fDate(v);

    default:
      return "-";
  }
}

export function date2str(date, time = false, full = false, seconds = false) {
  if (!date) return "";
  date = parseDate(date);
  if (!(date instanceof Date) || isNaN(date.getTime())) return date.toString();

  const dmy = full ? [ add0(date.getDate()), of_months[date.getMonth()], add0(date.getFullYear(), 4) ].join("\u00a0")
                   : [ add0(date.getDate()), add0(date.getMonth() + 1), add0(date.getFullYear(), 4) ].join(".");

  if (!time) return dmy;

  return dmy + ",\u00a0" + add0(date.getHours()) + ":" + add0(date.getMinutes()) + (seconds ? `:${ add0(date.getSeconds()) }` : "");
}

export const time2str = (date, seconds = false, ms = false) => {
  if (date && !(date instanceof Date)) date = parseDate(date);
  if (date && date instanceof Date) return `${ add0(date.getHours()) }:${ add0(date.getMinutes()) }${ seconds ? ":" + add0(date.getSeconds()) : '' }${ ms ? "." + add0(date.getMilliseconds(), 3) : '' }`
  return `--:--${ seconds ? ":--" : "" }`;
}

export const fDate = v => weekdays[getWeekday(v)] + "., " + [
  v.getDate(),
  of_months[v.getMonth()].toLowerCase(),//.substr(0, 3),
  v.getFullYear(),
].filter(a => a).join(" ");

export const getPeriodTooltip = (values, step) => {
  const min = Math.min(...values)
  const max = Math.max(...values)
  return v => {
    if (!v || !step) return "-";

    const nxt =
      step === "month"
      ? new Date(v.getFullYear(), v.getMonth() + 1, v.getDate(), v.getHours(), v.getMinutes(), v.getSeconds(), v.getMilliseconds() - 1)
      : step === "week"
        ? new Date(v.getTime() + 7 * 86400 * 1000 - 1)
        : step === "hour"
          ? new Date(v.getTime() + 3600 * 1000 - 1)
          : new Date(v.getTime() + 86400 * 1000 - 1)

    // Less or equal 1 HOUR
    if (step === "hour") {
      if (date2str(max) !== date2str(min))
        return [
                 v.getDate(), of_months[v.getMonth()], v.getFullYear(),
               ].join(" ") + ", " + time2str(v)
      return time2str(v)
    }

    if (step === "month") return [
      capitalize(months[v.getMonth()]),
      v.getFullYear(),
    ].join(" ")

    // less or equal 1 DAY
    if (step === "day") return [
      weekdays[getWeekday(v)] + ".,",
      v.getDate(),
      of_months[v.getMonth()],
      v.getFullYear(),
    ].join(" ")

    // SAME MONTH
    if (v.getMonth() === nxt.getMonth()) return (
      [
        [ v.getDate(), nxt.getDate() ].join(" – "),
        of_months[v.getMonth()],
        v.getFullYear(),
      ].join(" "))

    // SAME YEAR
    if (v.getFullYear() === nxt.getFullYear()) return (
      [
        [ v.getDate(), of_months[v.getMonth()] ].join(" "),
        [ nxt.getDate(), of_months[nxt.getMonth()] ].join(" "),
      ].join(" – ") + " " + v.getFullYear()
    )

    return `${ v.getDate() } ${ of_months[v.getMonth()] } ${ v.getFullYear() } – ${ nxt.getDate() } ${ of_months[nxt.getMonth()] } ${ nxt.getFullYear() }`;
  }
}

export const formatNumber = (num, round = 2, separator = "\u00a0") => {
  if (round === 0) num = Math.round(num);
  else if (round !== null) num = (Math.round(num * Math.pow(10, round)) / Math.pow(10, round)).toFixed(round)
  const [ x1, x2 = "" ] = num.toString().split('.');
  const formatThousands = val => {
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(val)) val = val.replace(rgx, '$1' + separator + '$2');
    return val;
  }
  // return formatThousands(x1) + (x1.length < round + 1 ? "." + x2.substr(0, round + 1 - x1.toString().length) : "");
  return formatThousands(x1) + (round > 0 ? "." + (x2.substr(0, round)) : "")
  //round + 1 - x1.toString().length) : "");
}

export const getShortText = (str, limit = 100, useEnd = 0) => {
  if (!str) return "";
  const end = typeof useEnd === "number" ? useEnd : (useEnd === true ? 3 : 0);
  return str.length > limit
         ? (
           !useEnd
           ? str.substr(0, limit - 1) + "\u2026"
           : str.substr(0, limit - 1 - end) + "\u2026" + str.substr(-end)
         )
         : str
};

export const capitalize = str => str.substring(0, 1).toLocaleUpperCase() + str.substring(1);

export const bindFunctions = (self, arr) => {
  if (!self || !arr || !Array.isArray(arr)) return;
  arr.forEach(func => { if (typeof self[func] === "function") self[func] = self[func].bind(self) })
}

export const getDate = value => {
  if (!value || value === "") return null;
  const d = parseDate(value);
  if (!d) return null;
  const add0 = i => `0${ i }`.substr(-2);
  return [
    d.getFullYear(),
    add0(d.getMonth() + 1),
    add0(d.getDate()),
  ].join("-")
}

export function period2str(start, end, view_weekdays = false) {
  if (!start || !(start instanceof Date)) return "";

  const wd = d => view_weekdays && d && (d instanceof Date) ? weekdays[getWeekday(d)] + ", " : "";

  if (!end || !(end instanceof Date)) return `${ start.getDate() } ${ of_months[start.getMonth()] } ${ start.getFullYear() } – ...`;

  if (start.getFullYear() === end.getFullYear()) {
    // if (start.getMonth() === end.getMonth()) {
    if (start.getMonth() === end.getMonth() && !view_weekdays) {
      // if (start.getDate() === end.getDate())
      return `${ wd(start) }${ start.getDate() } – ${ wd(end) }${ end.getDate() } ${ of_months[start.getMonth()] } ${ start.getFullYear() }`;
    }
    else return `${ wd(start) }${ start.getDate() } ${ of_months[start.getMonth()] } – ${ wd(end) }${ end.getDate() } ${ of_months[end.getMonth()] } ${ start.getFullYear() }`;
  }
  else return `${ wd(start) }${ start.getDate() } ${ of_months[start.getMonth()] } ${ start.getFullYear() } – ${ wd(end) }${ end.getDate() } ${ of_months[end.getMonth()] } ${ end.getFullYear() }`;
}

export function removeEffects(el, timeout = 100, callback = null, full = false) {
  if (!el) return;
  const cls = full ? "notransition" : "notrans";

  if (el._timerEffects) clearTimeout(el._timerEffects);
  el.classList.add(cls);
  el._timerEffects = setTimeout(() => {
    if (!el) return;
    delete el._timerEffects;
    el.classList.remove("notransition", "notrans");
    if (callback) callback();
  }, timeout)
}

export const random = (max, min = 0, integer = true) =>
  integer ? Math.round(Math.random() * (max - min) + min)
          : Math.random() * (max - min) + min;

export function randomString(length) {
  length = length > 0 ? length : 16;
  let result = '';
  // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));

  return result;
}

const ServerError = (id, params) => {
  if (!id) return "Неизвестная ошибка";
  let res = ServerErrors[id.toString()]
  if (params && typeof params === "object") {
    Object.keys(params)
      .forEach(key => {
        if (key === "core_err") res = res.replaceAll("%CORE_ERR_MESSAGE%", CoreErrors[params[key].toString()] || `Ошибка ядра ${ params[key] }`)
        else res = res.replaceAll(`%${ key.toUpperCase() }%`, params[key] || key.toUpperCase())
      })
  }
  return res || `Ошибка сервера ${ id }`;
}

export const PostError = (err) => {
  if (typeof err === "string") return err;
  const url = err?.url ? err.url.replace(URL_API_APP, "") : null;

  const textVsURL = text => `${ url ? `${ url }\n` : "" }${ text }`;
  if (err?.error_code !== undefined) return {
    ...err,
    url: url,
    description: textVsURL(
      ServerError(err.error_code, err?.error_params || null)
      || err?.description
      || "Неизвестная ошибка",
    ),
  }
  if (err?.status && ServerError(err.status)) return {
    ...err,
    url: url,
    status: err?.status,
    description: textVsURL(
      `Во время выполнения произошла ошибка:\n${ ServerError(err.status) }`,
    ),
  }

  if (err.description)
    return {
      ...err,
      url: url,
      description: textVsURL(
        `Во время выполнения произошла неизвестная ошибка:\n${ err?.description }`,
      ),
    };
  return {
    ...err,
    url: url,
    description: textVsURL(
      `Во время выполнения произошла неизвестная ошибка${ err?.status ? `\n${ err.status }` : "" }`,
    ),
  };
}

/*
const fetchError = err => {
  if (err && err.name === "AbortError") return;
  if (err.status === 401) {
    if (document._logout) document._logout();
  }
  throw new PostError(err);
};
//*/
export async function postJSON(options, controller = null, force = false) {
  const { url, timeout = _DEFAULT_TIMEOUT, method = "POST", post } = options;

  controller = controller || new AbortController()
  const timer = setTimeout(() => controller.abort(), timeout);

  return new Promise((resolve, reject) => {
    fetch(url, {
      // mode: 'no-cors',
      mode: 'cors',
      method: method || 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      body: JSON.stringify({
                             token: getTokenKey(),
                             ...post,
                           }),
      signal: controller.signal,
    })
      .then(res => {
        if (res?.status === 401) {
          if (document._logout) document._logout()
          return Promise.reject(res);
        }
        if (!res.ok) return Promise.reject(res)
        if (typeof res.status === "number" && res.status >= 400) return Promise.reject(res);
        return res.text();
      })
      .then(txt => {
        try {
          return JSON.parse(txt)
        }
        catch (e) {
          return Promise.reject(e)
        }
      })
      .then(resolve)
      .catch(async err => {
        const json = err.json ? await err.json() : null;
        return Promise.reject({
                                name: err.name,
                                message: err.message,
                                status: json?.error_code || json?.statusCode || json?.status || err.status,
                              })
      })
      .catch(err => {
        if (force) return Promise.reject(err);
        else if (err?.name === "AbortError") return null;
        // else if (err?.name === "TypeError") return Promise.reject({err,});
        else if ("SyntaxError" === err?.name || (err?.name && err?.message)) return Promise.reject(
          {
            ...err,
            description: err.message,
          });

        return Promise.reject(PostError(err))
      })
      // .catch(res => Promise.reject((res)))
      .catch(reject)
      .finally(() => {
        if (timer) clearTimeout(timer);
      })
  })
}

export function downloadFile(url, filename, post, timeout = 60000) {
  // const { timeout = _DEFAULT_TIMEOUT, method = "POST" } = data || {};
  const controller = new AbortController()
  setTimeout(() => controller.abort(), timeout);

  return fetch(url, {
    // mode: 'no-cors',
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify({
                           token: getTokenKey(),
                           ...post,
                         }),
    signal: controller.signal,
  })
    .then(data => {
      if (data.status !== 200 && data.status !== 201) return Promise.reject(data);
      return data.blob();
    })
    .then(blob => {
      // const b = new Blob([ blob ], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    })
  // .catch(fetchError)
}

export const hmmss = (time, format = false, space = "\u00a0", seconds = true, force = false) => {
  if (!force && (time === null || time === undefined || typeof time !== "number")) return "-";
  // if (!time) return "-";
  time = time || 0;
  const h = Math.floor(time / 3600);
  const m = Math.floor(time % 3600 / 60);
  const s = Math.round(time % 60);
  const add0 = v => `${ v < 10 ? "0" : "" }${ v }`

  // if (format) return `${ h }ч${ space }${ add0(m) }м${ space }${ add0(s) }с`
  if (format) return [
    h + "ч",
    add0(m) + "м",
    seconds && (add0(s) + "с"),
  ].filter(a => !!a).join(space)

  // return `${ h }:${ add0(m) }:${ add0(s) }`
  return [ h, add0(m), seconds && add0(s) ].filter(a => !!a).join(":")
}
export const dhhmmss = (time, space = "\u00a0", force = false) => {
  if (!force && (time === null || time === undefined || typeof time !== "number")) return "-";

  time = time || 0;
  const d = Math.floor(time / 86400);
  const h = Math.floor(time % 86400 / 3600);
  const m = Math.floor(time % 3600 / 60);
  const s = time % 60;
  const add0 = v => `${ v < 10 ? "0" : "" }${ v }`

  return `${ d }дн${ space }${ add0(h) }ч${ space }${ add0(m) }м${ space }${ add0(s) }с`
}

export const convertBytes = bytes => {
  const units = [ "Б", "КБ", "МБ", "ГБ", "ТБ" ]
  let step = 0;
  let val = typeof bytes === "number" && isFinite(bytes) && bytes > 0 ? bytes : 0;
  do {
    if (val < 1024 || units[step + 1] === undefined) break;
    val /= 1024;
  } while (++step)

  return `${ formatNumber(val, !step ? 0 : 2) }\u00a0${ units[step] }`;
}

export const handleSlide = carousel => a => {
  if (typeof carousel === "string") carousel = document.getElementById(carousel);
  if (!carousel || !carousel?.querySelector) return;
  const inner = carousel.querySelector(".carousel-inner");
  if (!inner) return;
  const last = inner.children[carousel?.lastStep || 0];
  carousel.style.overflow = "hidden";
  carousel.style.height = (last.offsetHeight || inner.offsetHeight) + "px";
  inner.style.overflow = "hidden";

  setTimeout(() => {
    if (inner.children[a]) carousel.style.height = (inner.children[a].offsetHeight) + "px";
  }, 0)
}

export const handleSlid = carousel => a => {
  let el = carousel;
  if (typeof carousel === "string") el = document.getElementById(carousel);
  if (!el) return;
  el.style.overflow = "visible";
  el.style.height = "";
  // carousel.style.width = carousel.offsetWidth + "px";
  const inner = el.querySelector(".carousel-inner");//querySelector(".cou")
  if (!inner) return;
  inner.style.overflow = "visible";
  // if (cur) carousel.style.minWidth = Math.max(350, cur.offsetWidth || inner.offsetWidth) + "px";

  el.lastStep = a;
}

export const isDark = () => localStorage.getItem("dark");
