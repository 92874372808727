import { useCallback, useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Border, cancelEvent, cancelFocus, PostError, randomString, time2str } from "../Global/functions";
import { BeelineIcon } from "../Elements/BeelineIcon";

import { ReactComponent as IconClose } from "@beeline/design-tokens/assets/icons/close.svg";

const LIMIT = 5;

const Notifications = () => {
  const [ notifications, setNotifications ] = useState([]);

  const add = useCallback(n => {
    const id = "notification_" + (n.id || randomString(16))
    setNotifications(old => ([
        {
          ...n,
          id: id,
          time: new Date(),
        },
        ...old.filter(o => o?.id !== id),
      ]
        .slice(0, LIMIT)
    ))
  }, [])

  const remove = useCallback(id => {
    setNotifications(old => old
      .filter(val => val?.id !== id)
      .reduce((res, val) => ([ ...res, val ]), []),
    )
  }, [])

  useEffect(() => {
    document.addNotification = add;

    return () => {
      delete document.addNotification;
    }
  }, [ add ])

  useEffect(() => {
    document.removeNotification = remove;

    return () => {
      delete document.removeNotification;
    }
  }, [ remove ])

  /*
  useEffect(() => {
    add({ children: "TEST 1", timeout: 5000 })
    add({ children: "TEST 2", timeout: 30000 })
  }, [ add ])
  //*/

  return (
    <div
      id="notifications"
    >
      {
        notifications
          .map(notification =>
                 <Notification
                   { ...notification }
                   key={ notification.id }
                   // key={ `${ notification.id }-${ notification.time.toISOString() }` }
                   onRemove={ () => remove(notification.id) }
                 />) }
    </div>
  )
}

const Notification = ({
                        id,
                        className,
                        timeout = 5000,
                        color = "danger",
                        onRemove,
                        children,
                        time,
                      }) => {
  const [ visible, setVisible ] = useState(false);

  const hide = useCallback(() => {
    if (!timeout) return;
    // const h = () => {
    setVisible(false);
    const timer = setTimeout(() => {
      if (document.removeNotification) document.removeNotification(id)
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [ id, timeout ])

  useEffect(() => {
    if (!timeout) return;
    setVisible(true)
    const timer = setTimeout(hide, timeout);

    return () => {
      setVisible(false)
      clearTimeout(timer)
    }
  }, [ hide, timeout ])

  useEffect(() => {
    const current = document.getElementById(id);
    if (!current) return;
    const el = current.querySelector(".alert")
    current.style.height = "0";
    const resize = () => current.style.height = visible && el ? el.scrollHeight + Border.height(el) + "px" : 0;
    const obs = new ResizeObserver(resize);
    if (el) obs.observe(el);
    else obs.observe(current)

    return () => {
      obs.disconnect()
      current.style.height = "0";
    }
  }, [ id, visible ])

  return <div
    id={ id }
    className={ [
      "notification",
      visible ? "show" : "hide",
    ].join(" ") }
    style={ {
      height: 0,
    } }
  >
    <Alert
      variant={ color }
      className={ [
        className,
        "small",
        "m-0",
        "py-2",
        "position-relative",
        "overflow-hidden",
        "w-100",
        onRemove && "alert-hover",
      ].filter(a => !!a).join(" ")
      }
      onClick={ e => {
        hide();
        return cancelEvent(e)
      } }
    >
      <Row className="gx-2 gy-0 align-items-center lh-12">
        <Col
          children={ children }
          style={ { whiteSpace: "pre-wrap" } }
        />
        <Col
          xs="auto"
          className="smallest fw-bold"
          children={ time2str(time) }
        />
        <Col
          xs="auto"
          children={
            <BeelineIcon
              size={ 22 }
              icon={ IconClose }
              onFocus={ cancelFocus }
              onClick={ e => {
                hide()
                return cancelEvent(e)
              } }
            />
          }
        />
      </Row>
      {
        typeof timeout === "number" && timeout > 0 &&
        <Timer
          timeout={ timeout }
          color={ color }
          // visible={ visible }
          id={ id }
        />
      }
    </Alert>
  </div>
}

const Timer = ({
                 timeout, color,
                 id,
               }) => {
  useEffect(() => {
    const current = document.getElementById(`timer_${ id }`);
    current.classList.add("notrans")
    current.style.width = "100%";

    const timer = setTimeout(() => {
      current.classList.remove("notrans")
      current.style.width = "0"
    }, 100)

    return () => {
      clearTimeout(timer)
      current.classList.add("notrans")
      current.style.width = "100%";
    }
  }, [ id ]);

  return <div
    id={ `timer_${ id }` }
    className="position-absolute bottom-0 start-0"
    style={ {
      transition: `width ${ timeout }ms linear 0s`,
      height: "2px",
      backgroundColor: `var(--bs-${ color })`,
    } }
  />
}

export const addNotification = ({
                                  id = undefined,
                                  className,
                                  timeout = 5000,
                                  color = "primary",
                                  onRemove,
                                  children,
                                }) => {
  if (!document.addNotification) return;
  document.addNotification({ id, className, timeout, color, onRemove, children })
}

export const addErrorNotification =
  err => addNotification({
                           timeout: 5000,
                           color: "danger",
                           ...err,
                           children: PostError(err)?.description || "Неизвестная ошибка",
                         })

export default Notifications;