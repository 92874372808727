export const _DEFAULT_TIMEOUT = 60 * 1000;
// export const __DEV__ = localStorage.getItem("debug") || (process.env.NODE_ENV !== 'production' && /localhost/.test(window.location.host));
export const __DEV__ = localStorage.getItem("debug") || (process.env.REACT_APP_DEV === "true");
// export const __DEV__ = true;

export const URL_API_APP = `https://${ __DEV__ ? "mwfdit-dev.apps.yd-kt05.vimpelcom.ru" : window.location.host }/api`;
// export const URL_API_APP = `${ __DEV__ ? "https://mwfdit-dev.apps.mn-kp01.vimpelcom.ru" : "" }/api`;
// export const URL_API_APP = `http://${ window.location.host }/api`;

export const of_months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export const weekdays = [
  "Пн",
  "Вт",
  "Ср",
  "Чт",
  "Пт",
  "Сб",
  "Вс",
];
export const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];