import { Alert, Col, FloatingLabel, FormControl, Modal } from "react-bootstrap";
import { useMemo, useState } from "react";
import ButtonIcon from "../Elements/ButtonIcon";
import { ReactComponent as IconSave } from "@beeline/design-tokens/assets/icons/save.svg";
import { ReactComponent as IconEye } from "@beeline/design-tokens/assets/icons/eye.svg";
import { ReactComponent as IconAlert } from "@beeline/design-tokens/assets/icons/warning_triangle.svg";
import { SVGroup } from "../Elements/SVGroup";
import { cancelEvent } from "../Global/functions";
import { BeelineIcon } from "../Elements/BeelineIcon";

// const reg = /^([a-z\d-_]+){10,}$/i
const minLength = 8;
const isValidPassword = pwd => {
  return [
           /^[a-zа-яё\d-_!@#$%^&*=\\|/<>:;"'{}[\]()~]{8,}$/i,
           /[a-zа-яё]/,
           /[A-ZА-ЯЁ]/,
           /\d/,
         ].reduce((res, reg) => res && reg.test(pwd), true)
         && pwd.length >= minLength;
}

const PasswordChange = ({ visible, onSave, onCancel }) => {
  const [ old, setOld ] = useState("");
  const [ p1, setP1 ] = useState("");
  const [ p2, setP2 ] = useState("");

  const valid = useMemo(() => {
    if (!p1 || !p2 || !old) return false;
    if (p1 === old) return false;
    if (p1 !== p2) return false;
    if (!isValidPassword(p1)) return false;
    if (!isValidPassword(p2)) return false;

    return true
  }, [ old, p1, p2 ]);

  return <Modal
    show={ visible }
    // onEntered={ onEntered }
    onExited={ onCancel }
    onHide={ onCancel }
    // className={ variant ? " modal-" + variant : "" }
    dialogClassName="shadow rounded"
  >
    <Modal.Header closeButton>
      <h5>Изменение пароля</h5>
    </Modal.Header>
    <Modal.Body>
      <Alert
        variant="primary"
        className="lh-12 d-flex gap-2 align-items-center"
      >
        <div
          children={
            <BeelineIcon
              icon={ IconAlert }
              size={ 40 }
            />
          }
        />
        <div
          children="Изменение текущего пароля повлияет на авторизацию всех пользователей Портала статистики."
        />
      </Alert>
      <form
        onSubmit={ e => {
          if (valid) onSave()
          return cancelEvent(e);
        } }
      >
        <FormRow
          label="Старый пароль"
          name="old_pwd"
          onChange={ setOld }
          value={ old }
          invalid={ !old ? "Обязательное поле" : undefined }
        />

        <FormRow
          label="Новый пароль"
          name="p1"
          onChange={ setP1 }
          value={ p1 }
          invalid={ !p1 ? "Обязательное поле" : (!isValidPassword(p1) ? "Пароль должен содержать заглавные и строчные буквы, цифры. Длина не менее 8 символов." : undefined) }
        />

        <FormRow
          label="Повторите пароль"
          name="p2"
          onChange={ setP2 }
          value={ p2 }
          invalid={ !p2 ? "Обязательное поле" : (p1 === old ? "Новый пароль должен отличаться от старого" : (p1 !== p2 ? "Пароли не совпадают" : undefined)) }
        />
      </form>
    </Modal.Body>
    <Modal.Footer className="d-flex align-items-center justify-content-end gx-1">
      <Col xs="auto">
        <ButtonIcon
          size="sm"
          variant="outline-white"
          text="Отмена"
          onClick={ e => onCancel(e) }
        />

      </Col>
      <Col xs="auto">
        <ButtonIcon
          size="sm"
          variant="outline-danger"
          icon={ IconSave }
          text="Изменить пароль"
          onClick={ e => valid && onSave(p1, old) }
          disabled={ !valid }
        />
      </Col>
    </Modal.Footer>
  </Modal>
}

const FormRow =
  ({ name, label, value, onChange, invalid = false, required = false }) => {
    const [ visible, setVisible ] = useState(false);

    return <>
      <SVGroup
        className="mb-1"
        style={ { minHeight: "3rem" } }
        size="sm"
        isInvalid={ !!invalid }
        element={
          <FloatingLabel
            label={ label }
          >
            <FormControl
              type={ visible ? "text" : "password" }
              placeholder={ label }
              size="sm"
              className="bg-white font-monospace"
              name={ name }
              value={ value }
              onChange={ e => onChange(e.target.value.trim()) }
              isInvalid={ invalid }
              required={ required }
            />

            {/*            <FormControl.Feedback
              // tooltip
              type="invalid"
              className="mb-2"
              children={ invalid || "Обязательное поле" }
            />*/ }
          </FloatingLabel>
        }
        append={
          <ButtonIcon
            icon={ IconEye }
            size="sm"
            className="btn-link"
            onMouseOver={ () => setVisible(true) }
            onMouseOut={ () => setVisible(false) }
          />
        }
      />

      { !!invalid &&
        <div
          className="mb-2 lh-12 text-danger small invalid-feedback d-block"
          children={ invalid }
        />
      }
    </>
  }

export default PasswordChange;