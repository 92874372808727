import { Alert, Spinner } from "react-bootstrap";

const Loading = ({ children = "Загрузка", className }) => {
  return <Alert
    variant="primary"
    className={ `d-block mx-auto text-center ${ className || "" }` }
    style={ {
      maxWidth: "min(100%, 15rem)",
      marginTop: 0,
      marginBottom: 0,
    } }
  >
    <Spinner
      size="sm"
      className="d-inline-block me-2 p-0"
    />
    { children }
  </Alert>
}

export default Loading;