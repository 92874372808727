import { useLocation } from "react-router-dom";
import Infos from "../Global/Strings/Informations.json"
import { CloseButton } from "react-bootstrap";
import { cloneElement, createElement, isValidElement, useEffect, useMemo, useState } from "react";
import { getScrollXY } from "../Global/functions";
import { BeelineIcon } from "../Elements/BeelineIcon";
import { ReactComponent as IconDownload } from "@beeline/design-tokens/assets/icons/download.svg";
import { ReactComponent as IconScreenshot } from "@beeline/design-tokens/assets/icons/camera.svg";
import { ReactComponent as IconClose } from "@beeline/design-tokens/assets/icons/close.svg";
import { ReactComponent as IconDashboard } from "@beeline/design-tokens/assets/icons/dashboard_dots.svg";
import { ReactComponent as IconEdit } from "@beeline/design-tokens/assets/icons/edit.svg";
import { ReactComponent as IconUp } from "@beeline/design-tokens/assets/icons/nav_arrow_up.svg";
import { ReactComponent as IconRemove } from "@beeline/design-tokens/assets/icons/delete.svg";
import { ReactComponent as IconAdd } from "@beeline/design-tokens/assets/icons/add.svg";
import { ReactComponent as IconRefresh } from "@beeline/design-tokens/assets/icons/refresh.svg";

const maxW = "max(20vw, 320px)";

export const InformationExists = short => short && !!Infos[short]

export const getShortPathname = path => path.match(/^\/([^/]*)/)[1] || "dashboard";

const Informations =
  ({
     visible,
     onHide,
   }) => {
    const { pathname } = useLocation();
    const short = getShortPathname(pathname)

    const info = useMemo(() => short ? Infos[short] : null, [ short ]);

    useEffect(() => {
      const root = document.getElementById("root");
      if (!root) return;
      root.style.transition = "margin-right .15s ease-out";
      // root.style.marginRight = visible ? `calc(${ maxW } + .75rem)` : ""
      root.style.marginRight = visible && info ? maxW : ""
    }, [ visible, info ])

    if (!short || !info) return null;
    // if (!info) return null;

    return <div
      id="informations"
      className={ [
        !visible && "opacity-0",
        !visible && "pointer-events-none",
        "position-fixed",
        "start-100",
        "top-0",
        "bottom-0",
        "shadow",
        "px-4",
        "py-3",
        // "bg-white",
        // "border-start",
        "overflow-auto",
        "small",
      ].filter(a => !!a).join(" ")
      }
      style={ {
        opacity: 1,
        zIndex: 101,
        width: maxW,
        // backgroundColor: "rgba(var(--bs-primary-rgb), .04)",
        transition: "transform .15s ease-out, opacity .15s ease-out",
        transform: visible ? `translateX(calc(-1 * ${ maxW }))` : "translateX(0)",

        // re-skin scrollbars
        "--selection-bg": "#aa9124",
        "--selection-color": "#ffffff",
      } }
    >
      <div
        // className="text-end"
        className="float-end"
        children={
          <CloseButton
            className="ms-2 mb-2"
            onClick={ () => onHide() }
          />
        }
      />
      { <InformationsRows rows={ info }/> }
    </div>
  }

const InformationsRows = ({ rows }) =>
  rows.map(
    (r, idx) =>
      Array.isArray(r)
      ? <InformationsRows
        rows={ r }
      />
      : <Information
        key={ idx }
        { ...r }
      />,
  )

const Information = ({ element, text, title, subtitle }) => {
  const [ hover, setHover ] = useState(false);
  const elems = element ? document.querySelectorAll(`[data-info="${ element }"]`) : null;

  useEffect(() => {
    if (!elems) return;
    const els = []
    if (!hover) return;
    elems.forEach(el => {
      if (!el || !el.offsetWidth) return;
      const l = document.createElement("div");
      l.classList.add(
        "position-absolute",
        "info",
      )
      const styles = window.getComputedStyle(el);
      const top = el.getBoundingClientRect().top + getScrollXY()[1];
      l.style.top = `${ top }px`;
      l.style.left = `${ el.getBoundingClientRect().left + getScrollXY()[0] }px`;
      l.style.width = `${ el.getBoundingClientRect().width }px`;
      l.style.height = `${ el.getBoundingClientRect().height }px`;
      l.style.borderRadius = styles.borderRadius;
      document.body.append(l);
      // el.scrollIntoView({
      //                     block: "center",
      //                     behavior: "smooth",
      //                   })
      window.scrollTo({ behavior: "smooth", top: top })
      els.push(l);
    })

    return () => {
      els.forEach(el => el && el.parentNode && el.parentNode.removeChild(el))
    }
  }, [ hover, elems ]);

  const replaceTags = txt => {
    if (Array.isArray(txt)) return txt.map(t => replaceTags(t)).flat()
    if (isValidElement(txt)) return cloneElement(txt, {}, replaceTags(txt.props.children))

    if (typeof txt !== "string") return txt;

    const splits = [ "b", "i" ]
    const getSplitReg = tag => new RegExp(`(<${tag}>.+?</${tag}>)`, "i")
    const getReplaceReg = tag => new RegExp(`<${tag}>(.+?)</${tag}>`, "gi")

    const start = txt.split(/(^-+ )/mgi)
      .map(t => {
        if (/^-- /mi.test(t)) return `-\u2002`;
        if (/^- /mi.test(t)) return `\u2022\u2002`;
        return t;
      })

    return splits
      .reduce((res, tag) => res.map(r => r.split(getSplitReg(tag))).flat(), start)
      .flat()
      .map((t, idx) => {
             const tag = splits.find(tag => getSplitReg(tag).test(t))

             return tag
                    ? createElement(tag, { key: idx }, t.replace(getReplaceReg(tag), "$1"))
                    : t
           },
      ).flat()
  }
  const replaceIcons = txt => {
    if (Array.isArray(txt)) return txt.map(t => replaceIcons(t)).flat()
    if (isValidElement(txt)) return cloneElement(txt, {}, replaceIcons(txt.props.children))

    return replaceTags(txt)
      .map(t =>
             typeof t !== "string"
             ? replaceIcons(t)
             : t
               .split(/(%[A-Z_]+%)/)
               .map((f, idx) => {
                 const icon = getIcon(f);
                 if (!icon) return f;

                 return <BeelineIcon
                   key={ idx }
                   icon={ icon }
                   className={ `d-inline-block align-middle ${ f === "%ICON_CLOSE%" ? "border  rounded" : "" }` }
                   style={ {
                     borderColor: "var(--bs-body-color)!important",
                   } }
                   size={ 16 }
                 />
               }),
      )
  }

  return <div
  >
    {
      title &&
      <h5
        className="mb-3"
        children={ title }
      />
    }
    <div
      className={
        !title
        ? [
          "alert",
          "alert-sm",
          "alert-primary",
          elems && "alert-hover",
          "px-3",
          "py-2",
          "mb-0",
          "mt-2",
        ].filter(a => !!a).join(" ")
        : undefined
      }
      onMouseOver={ () => setHover(true) }
      onMouseOut={ () => setHover(false) }
    >
      { subtitle &&
        <div
          className="fw-bold"
          children={ subtitle }
        />
      }
      {
        (
          Array.isArray(text)
          ? text
          : text.trim().split(/[\r\n]+/)
        )
          .map((t, idx) =>
                 <div
                   key={ idx }
                   className={ `${ title ? "mb-2" : "mb-1" }` }
                   children={ replaceIcons(t) }
                 />)
      }</div>
  </div>
}

const getIcon = icon => {
  switch (icon) {
    case "%ICON_DOWNLOAD%":
      return IconDownload;
    case "%ICON_SCREENSHOT%":
      return IconScreenshot;
    case "%ICON_CLOSE%":
      return IconClose;
    case "%ICON_DASHBOARD%":
      return IconDashboard;
    case "%ICON_EDIT%":
      return IconEdit;
    case "%ICON_REMOVE%":
      return IconRemove;
    case "%ICON_UP%":
      return IconUp;
    case "%ICON_ADD%":
      return IconAdd;
    case "%ICON_REFRESH%":
      return IconRefresh;
    default:
      return null;
  }
}

export default Informations;