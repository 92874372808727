import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";

// const InputElement = React.forwardRef((props,ref)=>{})
export function SVGroup(props) {
  const {
    prepend,
    append,
    isValid,
    isInvalid,
    element,
    onMouseOut,
    onMouseOver,
    error,
    className,
    noborder,
    // style,
    inline = false,
    readOnly = false,
    style,
    disabled = false,
    onClick,
    onDoubleClick,
  } = props;
  const addStyle = (el, className, style, id) => //addProps(el, { style: style });
    !el ? null
        : Array.isArray(el)
          ? el.map((l, idx) => addStyle(l,
                                        `${ l?.className || "" } ${ className }`,
                                        { ...l.style, ...style },
                                        idx,
          ))
          : React.isValidElement(el)
            ? React.cloneElement(el,
                                 {
                                   ...el?.props,
                                   key: id,
                                   className: `${ el.props?.className || "" } ${ className || "" }`.trim(),
                                   style: { ...el.props?.style, ...style },
                                 },
            )
            : React.createElement(el, {
              key: id,
              className: className,
              style: style,
            });

  const domProps = (el, props) =>
    el?.type?.displayName === "FloatingLabel"
    ? props
    : {
        ...props,
        isInvalid,
        isValid,
        readOnly,
      };

  return (<>
      <InputGroup
        // ref={ ref }
        size="sm"
        className={
          [
            "input-group-row",
            !inline ? "d-flex" : "d-inline-flex",
            "align-items-stretch",
            // "grid-0 g-0",
            !noborder ? "border" : "border-0",
            "rounded",
            // "overflow-hidden",
            /*!readOnly && */isInvalid ? "is-invalid" : null,
            /*!readOnly && */isValid ? "is-valid" : null,
            className,
            readOnly ? "readonly" : null,
            disabled ? "disabled" : null,
          ].filter(a => a).join(" ")
        }
        style={ style }
        // style={ {
        //   // marginTop: "-1px",
        //   ...style,
        // } }
        // isInvalid={ !(a || "").length }
        onMouseOver={ onMouseOver }
        onMouseOut={ onMouseOut }
        onClick={ onClick }
        onDoubleClick={ onDoubleClick }
      >
        {
          addStyle(prepend, "rounded-end-0 border-0 bg-transparent overflow-hidden", {
            zIndex: 5,
          })
        }
        {
          React.cloneElement(element,
                             domProps(element, {
                               ...element.props,
                               className: `${ element.props?.className || "" } flex-grow-1`.trim(),
                               // isInvalid,
                               // isValid,
                             }))
        }
        {
          addStyle(append, "rounded-start-0 bg-transparent overflow-hidden", {
            zIndex: 5,
          })
        }
      </InputGroup>
      {
        isInvalid && !readOnly && error &&
        <FormControl.Feedback
          type="invalid"
          className="lh-12"
          style={ {
            whiteSpace: "pre-wrap",
          } }
          children={ error }
        /> }
    </>
    // )
  )
}

SVGroup.propTypes = {
  append: PropTypes.oneOfType([ PropTypes.element, PropTypes.arrayOf(PropTypes.element) ]),
  prepend: PropTypes.oneOfType([ PropTypes.element, PropTypes.arrayOf(PropTypes.element) ]),
  element: PropTypes.oneOfType([ PropTypes.element, PropTypes.elementType, PropTypes.arrayOf(PropTypes.element) ]).isRequired,
  isInvalid: PropTypes.bool,
  error: PropTypes.string,
  noborder: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
}