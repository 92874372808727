import { createElement, createRef, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as IconDashboard } from "@beeline/design-tokens/assets/icons/dashboard_dots.svg";
import { ReactComponent as IconMap } from "@beeline/design-tokens/assets/icons/map.svg";
import { ReactComponent as IconSettings } from "@beeline/design-tokens/assets/icons/settings.svg";
// import IconLogo from "@beeline/design-tokens/assets/favicon/icon-192.png";
import { ReactComponent as IconLogo } from "@beeline/design-tokens/assets/favicon/icon.svg";
import { ReactComponent as IconStatistics } from "@beeline/design-tokens/assets/icons/stat_square_up.svg";
import { ReactComponent as IconLogout } from "@beeline/design-tokens/assets/icons/log_out.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { Col, ListGroup } from "react-bootstrap";
import { cancelEvent, cancelFocus, getPageSize, isDark, postJSON } from "../Global/functions";
import { BeelineIcon } from "../Elements/BeelineIcon";
import { ReactComponent as MoonStarsFill } from "@beeline/design-tokens/assets/icons/half_moon.svg";
import { ReactComponent as SunFill } from "@beeline/design-tokens/assets/icons/sun.svg";
import { ReactComponent as IconPassword } from "@beeline/design-tokens/assets/icons/lock.svg";
import { ReactComponent as IconArrRight } from "@beeline/design-tokens/assets/icons/fast_arrow_right.svg";
import { ReactComponent as IconArrLeft } from "@beeline/design-tokens/assets/icons/fast_arrow_left.svg";
import { ReactComponent as IconInfo } from "@beeline/design-tokens/assets/icons/info_circled.svg";
import PasswordChange from "./PasswordChange";
import SHA256 from "crypto-js/sha256";
import { addErrorNotification, addNotification } from "./Notifications";
import { URL_API_APP } from "../Global/constants";
import { getShortPathname, InformationExists } from "./Informations";

const MenuElements = {
  index: { title: "Дашборд", icon: IconDashboard },
  statistics: { title: "Статистика", icon: IconStatistics },
  map: { title: "Карта", icon: IconMap },
  contracts: { title: "Контракты", icon: IconSettings },
  logout: { title: "Выход", icon: IconLogout },
}

const Logotype = ({ small }) => {
  const navi = useNavigate();
  const w = small ? 32 : 48;
  return <div
    className="d-flex align-items-center gap-2 cursor-pointer link-black"
    style={ {
      transition: "margin .15s ease-out",
      height: "64px",
      zIndex: 100,
    } }
    onClick={ () => navi("/") }
  >
    <IconLogo
      viewBox="0 0 32 32"
      width={ w }
      height={ w }
      style={ {
        minWidth: "32px",
        transition: "width .15s ease-out, height .15s ease-out",
      } }
    />
    {
      <div
        className={ [
          "lh-12",
          "position-absolute",
          small ? "opacity-0 pointer-events-none" : "opacity-1",
          "text-nowrap",
        ].filter(a => !!a)
          .join(" ") }
        style={ {
          transition: [ "opacity", "left" ].map(key => `${ key } .15s ease-out`).join(","),
          left: w + 16 + 8 + "px",

        } }
      >
        <div className="fw-bold lh-1" children={ <>Портал<br/>статистики</> }/>
        {/*<div className="text-muted" children="TODO subtitle"/>*/ }
      </div>
    }
  </div>
}

const MenuItem = ({ item, url, small, id }) => {
  const { pathname } = useLocation();
  const { title, icon } = item;

  const navi = useNavigate();

  return <ListGroup.Item
    action
    active={ url === pathname }
    href={ url }
    onClick={ e => {
      navi(url)
      return cancelEvent(e)
    } }
    className={ [
      "link",
      "link-black",
      "border-0",
      "bg-transparent",
      "py-3",
      "lh-12",
      "gap-0",
      "d-flex",
      "position-relative",
      id === "logout" && "mt-3",
    ].filter(a => !!a).join(" ")
    }
  >
    { icon &&
      <Col
        xs="auto"
        style={ { width: "18px" } }
        children={ createElement(icon, {
          className: "d-inline-block me-2 align-top",
          width: 18,
          height: 18,
          fill: "currentColor",
          style: { lineHeight: "18px" },
        }) }
      />
    }
    {
      //!small &&
      <Col
        className={ [
          "position-absolute",
          small ? "opacity-0 pointer-events-none" : null,
        ].filter(a => !!a).join(" ") }
        style={ {
          transition: "opacity .15s ease-out, left .15s ease-out",
          left: 18 + 24 + "px",
        } }
        children={ title }
      />
    }
  </ListGroup.Item>
}

const Menu =
  ({
     small,
     setSmall,

     info,
     onInfo,
   }) => {
    const w = Menu.getWidth(small);
    const ref = createRef();

    useEffect(() => {
      const el = ref.current;
      const par = document.getElementById("menu");
      if (!el || !par) return;

      const w = Menu.getWidth(small);
      if (par) par.style.width = `${ w }px`;
      el.classList.add("position-fixed",
                       "top-0",
                       "start-0",
                       // "bottom-0",
      )
      el.classList.remove("h-100");

      return () => {
        if (el) el.classList.remove("position-fixed",
                                    "top-0",
                                    "start-0",
        )
      }
    }, [ w, small, ref ])

    const onScroll = useCallback(() => {
      const footer = document.getElementById("footer");
      const f = document.getElementById("menu-float");
      if (!f || !footer) return;

      const height = getPageSize()[1];
      const r = footer.getBoundingClientRect();

      let y = 0;
      if (r.top > height) y = r.height
      else if (height - r.top <= r.height) y = r.height - (height - r.top);

      y -= footer.getBoundingClientRect().height;

      f.style.transform = `translateY(${ y }px)`
    }, [])

    useEffect(() => {
      onScroll();

      const obs = new ResizeObserver(onScroll);
      obs.observe(document.querySelector("html"))
      obs.observe(document.querySelector("footer"))

      window.addEventListener("scroll", onScroll);

      return () => {
        obs.disconnect()
        window.removeEventListener("scroll", onScroll);
      }
    }, [ onScroll ]);

    return (<div
        ref={ ref }
        className="d-flex flex-column"
        style={ {
          width: `${ w }px`,
          transition: "width .15s ease-out",
          maxHeight: "inherit",
          height: "100%",
          flexGrow: 0,
        } }
      >
        <div
          className="mx-3 mt-2"
          children={ <Logotype small={ small }/> }
        />
        <div
          style={ {
            flexGrow: 1,
            transition: "padding .15s ease-out",
            overflowY: "auto",
            overflowX: "hidden",
            minHeight: "7rem",
          } } className={ small ? "px-1" : "px-3" }
        >
          <ListGroup
            variant="flush"
            className="rounded-0"
          >
            {
              Object.keys(MenuElements)
                .map(key =>
                       <MenuItem
                         key={ key }
                         id={ key }
                         item={ MenuElements[key] }
                         url={ key === "index" ? "/" : `/${ key }/` }
                         small={ small }
                       />)
            }
          </ListGroup>
        </div>

        <MenuFloating
          small={ small }
          setSmall={ setSmall }
          info={ info }
          onInfo={ onInfo }
        />
      </div>
    )
  };

const MenuFloating = ({ small, setSmall, info, onInfo }) => {
  const [ pwdChange, setPwdChange ] = useState(false);

  const { pathname } = useLocation();
  const short = getShortPathname(pathname)

  const w = useMemo(() => Menu.getWidth(small), [ small ]);
  const dark = useMemo(() => isDark(), []);

  const handlePasswordChange = useCallback((pwd, old) => {
    const post = {
      old: SHA256(old).toString(),
      pwd: SHA256(pwd).toString(),
    }
    postJSON({
               url: `${ URL_API_APP }/common/users/update`,
               post: post,
             })
      .then(res => {
        setPwdChange(false);
        addNotification({
                          id: "error_pwd_change",
                          color: "success",
                          children: "Пароль успешно изменен",
                        })
        return res;
      })
      .catch(err => {
        addErrorNotification({
                               id: "error_pwd_change",
                               status: err?.status,
                               url: err?.url,
                             })
      })
  }, []);

  const Btn = ({ className, disabled, style, button, text, ...props }) =>
    <div
      { ...props }
      className={ [
        !disabled && "btn-hover",
        !disabled && "cursor-pointer",
        "d-flex",
        "align-items-center",
        "justify-content-center",
        // small ? "justify-content-center"
        //       : "justify-content-end",
        // "mx-3",
        "px-1",
        className,
        disabled && "opacity-50",
        "text-nowrap",
        "w-100",
      ].filter(a => !!a).join(" ")
      }
      style={ {
        // height: "1.5rem",
        height: "2rem",
        ...style,
      } }
      onFocus={ cancelFocus }
    >
      { text && !small && <span className="d-inline-block me-1 text-muted flex-grow-1 text-end overflow-hidden" children={ text }/> }
      { button }
    </div>

  return <div
    id="menu-float"
    style={ {
      width: `${ w }px`,
      zIndex: 1111,
      transition: "width .3s ease-out",
      color: "#000000",
    } }
  >
    <PasswordChange
      visible={ pwdChange }
      onSave={ handlePasswordChange }
      onCancel={ () => setPwdChange(false) }
    />

    <Btn
      className="mb-3"
      onClick={ () => setPwdChange(!pwdChange) }
      title="Изменить пароль"
      // text="Изменить пароль"
      button={
        <BeelineIcon
          icon={ IconPassword }
          size={ 18 }
        />
      }
    />

    <Btn
      className={ info ? "btn-primary" : undefined }
      onClick={ () => onInfo(!info) }
      title="Показать/скрыть справку"
      // text="Справка"
      disabled={ !InformationExists(short) }
      // style={ { height: "3rem" } }
      button={
        <BeelineIcon
          icon={ IconInfo }
          size={ 16 }
        />
      }
    />

    <Btn
      onClick={ () => {
        if (dark) localStorage.removeItem("dark");
        else localStorage.setItem("dark", "1");
        window.location.reload();
      } }
      // text="Переключить тему"
      title="Переключиться между темной и светлой темой"
      button={
        <BeelineIcon
          size={ 16 }
          icon={ dark ? SunFill : MoonStarsFill }
        />
      }
    />

    <Btn
      onClick={ () => setSmall(!small) }
      title="Свернуть/Развернуть меню"
      // text="Свернуть меню"
      button={
        <BeelineIcon
          icon={ small ? IconArrRight : IconArrLeft }
          size={ 22 }
        />
      }
    />
  </div>
}

Menu.getWidth = small => small ? 64 : 190;

export default Menu;